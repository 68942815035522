import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModal } from "../redux/slices/modalSlice";
import { fetchLOB } from "../redux/slices/lobSlice";
import { deleteLob } from "../services/lobService";
import {successNotifier } from "../components/Notification";


const ConfirmPopup = () => {
  const dispatch = useDispatch();
  const confirmModal = useSelector((state) => state.modal.confirmModal);
  const [loading, setLoading] = useState(false);

  const confirmed = async () => {
    let res;
    setLoading(true);

    if (confirmModal.type === "deleteLob") {
      res = await deleteLob(confirmModal.payload);
      dispatch(fetchLOB());
    }

    console.log(res);
    

    setLoading(false);
    dispatch(setConfirmModal({ status: false, data: null }));
    if (res?.isSuccessful) {
      successNotifier("Operation executed Sucessfully");
    } else {
    //   errorNotifier(res?.message);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={confirmModal?.status}
      onClose={() => {
        dispatch(setConfirmModal({ status: false, data: null }));
      }}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody px={"50px"}>
          <Heading textAlign={"center"} color={"gray"}>
            {confirmModal?.title ? confirmModal?.title : " Are you sure?"}
          </Heading>
          <Text
            fontSize={"20px"}
            color={"gray"}
            textAlign={"center"}
            my={"30px"}
          >
            {confirmModal?.desc
              ? confirmModal?.desc
              : "Please Confirm you want to proceed"}
          </Text>

          <Flex justify={"center"} align={"center"} gap={"10px"}>
            <Button
              color={"white"}
              bg={"red"}
              onClick={() =>
                dispatch(setConfirmModal({ status: false, data: null }))
              }
              w={"50%"}
              _hover={{ background: "" }}
              mb={"30px"}
            >
              CANCEL!
            </Button>

            <Button
              color={"white"}
              bg={"brand.primary"}
              w={"50%"}
              _hover={{ background: "" }}
              mb={"30px"}
              onClick={confirmed}
              isLoading={loading}
              loadingText="Processing ..."
            >
              {confirmModal?.buttonText
                ? confirmModal?.buttonText
                : "YES, PROCEED!!!"}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmPopup;
