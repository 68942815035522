import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    confirmModal: {
      status: false,
      type: "",
      title: "",
      desc: "",
      payload: null,
      buttonText: "",
      showActionBtn: true,
    },
  },

  reducers: {
    setConfirmModal: (state, { payload }) => {
      state.confirmModal = {
        status: payload.status,
        type: payload.type,
        title: payload.title,
        desc: payload.desc,
        payload: payload.payload,
        buttonText: payload.buttonText,
        showActionBtn: payload.showActionBtn,
      };
    },
  },
});

export const { setConfirmModal } = modalSlice.actions;
export default modalSlice.reducer;
